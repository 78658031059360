//@ts-check
const BASE_URL = process.env.BASE_URL || '';
// console.log(BASE_URL)

module.exports = {
  BASE_URL,
  INCCA: {
    logo: `static/incca-gold.png`,
    favicon32: `static/cropped-favicon-INCCA-32x32.png`,
    home: "https://www.unincca.edu.co",
  },
};
