
$(() => {
  function updateCSSVariables() {
    $('html').css('--window-inner-width', window.innerWidth + 'px');
    $('html').css('--window-inner-height', window.innerHeight + 'px');
  }
  $(window).on('resize', () => updateCSSVariables());
  $(window).trigger('resize');
});

$('<style>').html(`
:root {
  --color-1: #031D3E;
  --color-2: #CD9D2F;
}
html{
  --window-inner-width: 100vw;
  --window-inner-height: 100vh;
}

@font-face {
  font-family: 'LatoBlack';
  src: url(static/lato/Lato-Black.ttf);
}
@font-face {
  font-family: 'Lato';
  src: url(static/lato/Lato-Regular.ttf);
}

body{
  margin: 0;
  font-family: 'Lato', sans-serif;
  color: var(--color-1);
}

.text-center { text-align: center;}
.text-right { text-align: right;}
.text-left { text-align: left;}
.bold { font-weight: bold; }
.block-head.no-bold { font-weight: inherit; }
.flex { display: flex; }
.full-width { width: 100%; }
.full-height { height: 100%; }
.hbox { display:flex; flex-direction: row; justify-content: space-around; align-items: stretch; }
.vbox { display:flex; flex-direction: column; justify-content: space-around; align-items: stretch; }
.hbox-compact { display:flex; flex-direction: row; }
.flex-grow {flex: 1}

.space-around { justify-content: space-around; }
.justify-center { justify-content: center; }
.justify-start { justify-content: start; }
.space-evenly { justify-content: space-evenly; }
.space-between { justify-content: space-between; }
.margin-auto { margin: auto; }
.align-items-center { align-items: center;}
.align-items-end { align-items: end;}
.align-items-stretch { align-items: stretch;}
.height-auto { height: auto; }
.strike-through { text-decoration: line-through; }

.font-s { font-size: 0.8em; }
.padding-0-m { padding: 0 1em; }
.padding-s-m { padding: 0.1em 1em; }

.box-shadow {
  box-shadow: 0 0px 8px rgba(0, 0, 0, 0.15);
}
`).appendTo('head');
