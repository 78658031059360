//@ts-check
import { INCCA } from '../.env';
import './global-css';

const defaults = {
  favicon: INCCA.favicon32,
  title: 'ID Unincca',
}

export default function ({ favicon, title }: { favicon?: string, title?: string } = defaults) {
  return <>
    {favicon && <link rel="icon" type="image/x-icon" href={favicon} />}
    {title && <title>{title}</title>}
  </>
}