//@ts-check
import Header from '../Header';
import { INCCA } from '../../.env';


$('<style>').html(`

  .main__central-button {
    background-color: var(--color-1);
    color: white;
    padding: 15px 25px;
    text-decoration: none;
    min-width: 20em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid var(--color-2);
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }
  .main__central-button:hover {
    background-color: var(--color-2);
  }
`).appendTo('head');


const Component = ({ }) => <div class="vbox space-between" style="height:100vh; width:100vw;">

  <div class="hbox space-between" style="height:1em; background-color: var(--color-2);"></div>
  {/* <div class="hbox space-between" style="height:1em; font-size: 0.9em;">
    <span></span>
    <span>
      Acceder como <a href="records/login">registro</a> o <a href="security/login">seguridad</a>.
    </span>
  </div> */}
  <div class="vbox flex-grow align-items-center">
    <div style="height:10vh"></div>
    <div class="hbox full-width" >
      <h1>Sistema digital de identificación</h1>
    </div>
    <div class="hbox">
      <a class="main__central-button" href="scan">Escáner público</a>
    </div>
    <div class="hbox">
      <a class="main__central-button" href="security/login">Seguridad</a>
    </div>
    <div class="hbox">
      <a class="main__central-button" href="records/login">Registro</a>
    </div>
    <div class="vbox align-items-center">
      <img src={INCCA.logo} style="max-width: 15em;" />
      <a href={INCCA.home} style="font-size: 0.7em;">{INCCA.home}</a>
    </div>
    <div style="height:10vh"></div>
  </div>
  <div class="hbox space-between" style="height:1em; background-color: var(--color-2);"></div>
</div>

$('head').append(<Header />);
$('body').append(<Component />);

